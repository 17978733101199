<template>
  <div class="h-full bg-gray-50">
    <template v-if="state.matches('data.general.fetched')">
      <div class="h-80 absolute top-0 w-full">
        <div class="bg-no-repeat h-full" :class="{ 'background-gradient': !hasBannerImage }"
          :style="bannerImageStyle">
        </div>
      </div>
      <div class="relative min-h-screen flex flex-col">
        <header class="relative z-40 lg:sticky lg:top-0">
          <LayoutHeader v-if="status === 'authenticated'" />
        </header>
        <main class="flex-1 pb-12 min-h-max">
          <template v-if="selectedInvestorHasReportingPower">
            <slot />
          </template>
          <div v-else class="flex justify-center">
            <UiAlert :content="t('errors.missing_reporting_power')" />
          </div>
        </main>
        <footer class="w-full">
          <LayoutFooter v-if="status === 'authenticated'" />
        </footer>
      </div>
    </template>
    <template v-else-if="state.matches('data.general.error')">
      <div class="h-screen flex flex-col justify-center items-center">
        <div v-if="errorMessage">{{ errorMessage }}</div>
        <div v-else>{{ t('errors.error_occured') }}</div>
        <BaseButton to="/logout" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">
          {{ t('ui.logout') }}
        </BaseButton>
      </div>
    </template>
    <template v-else>
      <div class="h-screen flex justify-center items-center">
        <IconLoader class="h-10 w-10 text-slate-500 animate-spin" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { provideClient } from '@urql/vue';
import mixpanel from 'mixpanel-browser';
import { InvestorContactPowers } from '~/generated/operations-airfund';
import { createGraphqlClient } from '~/utils/graphqlClient';
import { useCompanyStore } from '~/store/company';
import { useHomeStore } from '~/store/home'
import { useInvestmentsStore } from '~/store/investments';
import { usePortfolioStore } from '~/store/portfolio';
import { useDocumentsStore } from '~/store/documents';
import { useNewsStore } from '~/store/news';
import { useProductsStore } from '~/store/products';
import { useMachine } from '@xstate/vue';
import { investorMachine as xstateInvestorMachine } from '~/machines/investor.machine';
import { useI18n } from 'vue-i18n';
import { Cookie } from '~/utils/cookies';
import { useWindowScroll } from '@vueuse/core'
import { objectConfigToCSSVariables } from '~/utils/theming';
import { Theme } from '~/types/theme'
import { z } from 'zod'
import { useToast } from 'vue-toastification';

const { status } = useAuth()
const companyStore = useCompanyStore()
const investmentsStore = useInvestmentsStore()
const portfolioStore = usePortfolioStore()
const documentsStore = useDocumentsStore()
const newsStore = useNewsStore()
const productsStore = useProductsStore()
const cookieSelectedInvestorId = useCookie(Cookie.SELECTED_INVESTOR_ID)
const { t, locale } = useI18n()
const config = useRuntimeConfig()
const { y } = useWindowScroll()
const route = useRoute()
const errorMessage = ref('')
const toast = useToast()

const client = await createGraphqlClient()
provideClient(client)

const setTheme = () => {
  const style = document.documentElement.style
  const cssVariables = objectConfigToCSSVariables(companyStore.theme)
  Object.entries(cssVariables).forEach(([key, value]) => {
    style.setProperty(key, value)
  })
}

const selectValidInvestor = () => {
  let investors = companyStore.user.investors
  if (!companyStore.user.impersonationMode) {
    investors = investors.filter(item => item.powers.includes(InvestorContactPowers.InvestorContactPowerReporting))
  }
  const isCookieValid = investors.some(investor => investor.id === cookieSelectedInvestorId.value)
  if (isCookieValid) {
    companyStore.selectedInvestor = investors.find(investor => investor.id === cookieSelectedInvestorId.value)!
  } else {
    cookieSelectedInvestorId.value = investors?.[0]?.id
    companyStore.selectedInvestor = investors[0]
  }
}

const { state, send } = useMachine(xstateInvestorMachine, {
  services: {
    fetchLayoutData: async () => {
      const companyData = await repository.getCompanyData(client)
      Object.entries(companyData).forEach(([key, value]) => {
        companyStore[key] = value
      })
      locale.value = companyData.user.preferredLanguage || 'en'

      mixpanel.identify(companyStore.user?.id)
      mixpanel.people.set({
        $last_login: new Date(),
        'User Type': companyStore.user.impersonationMode ? 'SGP' : 'Investor',
      })
      const { $sentrySetUser } = useNuxtApp() 
      $sentrySetUser({
        id: companyStore.user.id,
        // email: companyStore.user.email, // NOTE: commented, not sure if we should send email
        // name: companyStore.user.name, // NOTE: commented, not sure if we should send name
        userType: companyStore.user.impersonationMode ? 'SGP' : 'Investor',
        preferredLanguage: companyStore.user.preferredLanguage,
      })

      if (!companyStore.user.emailValidated) {
        errorMessage.value = t('errors.email_not_validated')
        throw new Error(t('errors.email_not_validated'))
      }

      useHead({
        title: `${t('head.title')} - ${t('ui.investor_portal')} ${companyStore.name}`,
        link: [
          { rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }
        ]
      })

      const settings = await repository.getSettingsData(client)
      if (settings) {
        Object.entries(settings).forEach(([key, value]) => {
          if (key === 'CUSTOM_THEME') {
            try {
              value = JSON.parse(value as string)
              if (value && Theme.parse(value)) {
                companyStore.settings.CUSTOM_THEME = value as z.infer<typeof Theme>
              }
            } catch {
              console.error('Custom theme is not valid')
            }
          } else {
            companyStore.settings[key] = value
          }
        })
      }
      setTheme()

      const [investors, selectedInvestor] = await Promise.all([
        repository.getInvestors({ client, selectedInvestorId: cookieSelectedInvestorId.value || '' }),
        repository.getSelectedInvestor({ client, selectedInvestorId: cookieSelectedInvestorId.value || '' })
      ])
      if (investors.length === 0 && !selectedInvestor) {
        errorMessage.value = t('errors.investors_empty')
        throw new Error(t('errors.investors_empty'))
      }
      companyStore.user.investors = investors
      if (selectedInvestor) {
        companyStore.selectedInvestor = selectedInvestor
      } else {
        selectValidInvestor()
      }

      const categories = await repository.getCategoriesData(client)
      investmentsStore.categories = categories
    },
    fetchHomeData: async () => {
      const [homeData, contactsData] = await Promise.all([
        repository.getHomeData(client),
        repository.getContactsData({ client })
      ])

      if (homeData) {
        const { keyNumbers, notifications, news } = homeData
        const homeStore = useHomeStore()
        homeStore.keyNumbers = keyNumbers
        homeStore.notifications = notifications
        homeStore.news = news
      }

      if (contactsData) {
        companyStore.contacts = contactsData
      }
    },
    fetchInvestmentsData: async () => {
      const data = await repository.getInvestmentsData({ client, categoryCode: portfolioStore.selectedCategory })
      portfolioStore.keyNumbers = data.keyNumbers
      portfolioStore.investmentsByVintageYear = data.investmentsByVintageYear
      investmentsStore.categories = data.categories

      send('FETCH_TRANSACTIONS')
    },
    fetchTransactionsData: async () => {
      const categories = await repository.getTransactionsData({ client })
      investmentsStore.categories = categories
    },
    fetchDocumentsData: async () => {
      const { DISABLE_PERSONAL_DOCUMENTS_TAB, DISABLE_SUBSCRIPTION_DOCUMENTS_TAB } = companyStore.settings;
      if (DISABLE_PERSONAL_DOCUMENTS_TAB) {
        documentsStore.currentViewType = 'INVESTMENTS'
      }
      const [userDocumentsData, investmentsDocumentsData, noticesDocumentsData] = await Promise.all([
        (DISABLE_PERSONAL_DOCUMENTS_TAB && DISABLE_PERSONAL_DOCUMENTS_TAB) ? [] : repository.getUserDocumentsData(client),
        repository.getInvestmentsDocumentsData({ client }),
        repository.getNoticesDocumentsData({ client })
      ])
      if (userDocumentsData) {
        documentsStore.user = userDocumentsData.user
      }
      if (investmentsDocumentsData) {
        documentsStore.investments.documents = investmentsDocumentsData
      }
      if (noticesDocumentsData) {
        documentsStore.notices.documents = noticesDocumentsData
      }
      const currentYear = new Date().getFullYear().toString()
      const previousYear = (new Date().getFullYear() - 1).toString()

      const years = documentsStore.filters.years
      const currentYearFilter = years.find(year => year.value === currentYear)
      const previousYearFilter = years.find(year => year.value === previousYear)

      if (currentYearFilter && !documentsStore.selectedFilters.years.some(year => year.value === currentYearFilter.value)) {
        documentsStore.selectedFilters.years.push(currentYearFilter)
      }
      if (previousYearFilter && !documentsStore.selectedFilters.years.some(year => year.value === previousYearFilter.value)) {
        documentsStore.selectedFilters.years.push(previousYearFilter)
      }
    },
    fetchNewsData: async () => {
      const newsData = await repository.getNewsData(client)
      if (newsData) {
        const { news, categories } = newsData
        newsStore.news = news
        newsStore.categories = categories
      }
    },
    fetchProductsData: async () => {
      const productsData = await repository.getProductsData(client)
      if (productsData) {
        const { products, filters } = productsData
        productsStore.products = products
        // if filters are empty, set the filters
        if (productsStore.isFiltersEmpty) {
          productsStore.filters = filters
        }
      }
    }
  },
  devTools: true
})

const isLayoutLoaded = computed(() => state.value.matches('data.general.fetched'))

const investorMachine = computed(() => {
  return { state, send, isLayoutLoaded }
})

const isScrolled = computed(() => {
  return y.value > 0
})

const hasBannerImage = computed(() => {
  return companyStore.settings.BANNER !== null
})

const bannerImageStyle = computed(() => {
  if (hasBannerImage.value) {
    return `background-image: url("${companyStore.settings.BANNER}"); background-size: cover;`
  } else if (!!companyStore.theme?.colors?.header?.image) {
    const bgImage = `/${config.public.COMPANY}/img-splash.jpg`
    return `background-image: url("${bgImage}"); background-size: cover;`
  }
  return ''
})

const isError = computed(() => {
  return ['home', 'investments', 'transactions', 'documents', 'news', 'products'].some(page => {
    return state.value.matches(`data.${page}.error` as any)
  })
});

const selectedInvestorHasReportingPower = computed(() => {
  return companyStore.user.impersonationMode || companyStore.selectedInvestor.powers.includes(InvestorContactPowers.InvestorContactPowerReporting)
})

provide('investorMachine', investorMachine.value)
provide('isScrolled', isScrolled)

onBeforeMount(() => {
  if (status.value === 'unauthenticated') {
    navigateTo('/login')
  }
  setTheme()
  mixpanel.init(config.public.MIXPANEL_TOKEN, {
    persistence: 'cookie',
    cross_subdomain_cookie: true,
    debug: process.env.NODE_ENV === 'development'
  })
  mixpanel.track_pageview()
  send('FETCH_LAYOUT')
})

watch(() => route.fullPath, () => {
  if (isLayoutLoaded.value) {
    mixpanel.track_pageview()
  }
})

watch(isError, (value) => {
  if (value) {
    const dateNow = new Date()
    toast.error(dateNow.toLocaleString() + ' - ' + t('errors.data_error'), { timeout: false })
  }
})
</script>

<style lang="postcss">
@import 'microtip/microtip';
</style>

<style scoped>
.background-gradient {
  @apply bg-gradient-to-r from-header-gradient-0 to-header-gradient-1;
}
</style>